import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#111",
      d: "M8 13.333A5.334 5.334 0 1 0 8 2.666a5.334 5.334 0 0 0 0 10.667Zm0-12a6.667 6.667 0 0 1 0 13.334c-3.687 0-6.667-3-6.667-6.667A6.667 6.667 0 0 1 8 1.333Zm.333 3.334v3.5l3 1.78-.5.82-3.5-2.1v-4h1Z"
    }, null, -1)
  ])))
}
export default { render: render }
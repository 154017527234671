import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 50 50"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path stroke=\"#ECECEC\" stroke-linecap=\"round\" stroke-width=\"3\" d=\"M43.715 24a19.499 19.499 0 1 1-39 0\"><animateTransform attributeName=\"transform\" dur=\"3s\" from=\"0 24 24\" repeatCount=\"indefinite\" to=\"360 24 24\" type=\"rotate\"></animateTransform></path><path stroke=\"#111\" stroke-linecap=\"round\" stroke-width=\"3\" d=\"M11.715 24a12.501 12.501 0 0 1 25 0\"><animateTransform attributeName=\"transform\" dur=\"3s\" from=\"0 24 24\" repeatCount=\"indefinite\" to=\"-360 24 24\" type=\"rotate\"></animateTransform></path><path stroke=\"#ECECEC\" stroke-linecap=\"round\" stroke-width=\"3\" d=\"M19.715 24a4.5 4.5 0 0 0 9 0\"><animateTransform attributeName=\"transform\" dur=\"3s\" from=\"0 24 24\" repeatCount=\"indefinite\" to=\"360 24 24\" type=\"rotate\"></animateTransform></path>", 3)
  ])))
}
export default { render: render }
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M6.333 2a4.333 4.333 0 0 1 3.294 7.153l.18.18h.526l3.334 3.334-1 1-3.334-3.334v-.526l-.18-.18A4.333 4.333 0 1 1 6.333 2Zm0 1.333c-1.666 0-3 1.334-3 3 0 1.667 1.334 3 3 3 1.667 0 3-1.333 3-3 0-1.666-1.333-3-3-3Z" }, null, -1)
  ])))
}
export default { render: render }
<template>
  <div :class="$style.container">
    <div id="logo-branded-g" :class="$style.wrapper">
      <svg :class="$style.mask">
        <defs>
          <mask id="logo-mask">
            <image :href="logoSrc" />
          </mask>
        </defs>
      </svg>
      <div :class="$style.render">
        <canvas
          ref="renderedCanvas"
          :width="isMobile.value ? 33 : 50"
          :height="isMobile.value ? 33 : 50"
        ></canvas>
      </div>
      <div :class="$style.background">
        <img :src="logoSrc" alt="Logo" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import html2canvas from 'html2canvas';
import logoSrc from '@/assets/svgs/media/Guggenheim_Symbol_Gray_RGB.svg?url';
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useMainStore } from '@/stores/mainStore';
import { useRoute } from 'vue-router';
import { useHandleError, useScreenSize } from '@/composables/Common.js';

const { handleError } = useHandleError();
const { isMobile } = useScreenSize();
const route = useRoute();
const store = useMainStore();

const { pushItem } = store;
const { dataLoaded, logoCanvases } = storeToRefs(store);

const captureElement = ref<HTMLElement | null>(null);
const renderedCanvas = ref(null);

let capturedCanvas = null;

watch(dataLoaded, (loaded) => {
  if (loaded) {
    nextTick().then(() => captureDOMIntoCanvas());
  }
});

onMounted(() => {
  captureElement.value = document.getElementById('main');
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

async function captureDOMIntoCanvas(): void {
  nextTick(() => {
    html2canvas(captureElement.value, { scale: 1 })
      .then((canvas) => {
        const match = logoCanvases.value.find((element) => element.url === route.path);

        capturedCanvas = match ? match.canvas : canvas;

        if (!match && canvas.height !== 0 && canvas.width !== 0) {
          pushItem({ type: 'logoCanvases', value: { url: route.path, canvas } });
        }

        drawCapturedCanvas();
      })
      .catch((error) => {
        handleError('NavLogo', `Error capturing content: ${error}`, false);
      });
  });
}

function drawCapturedCanvas(): void {
  if (!capturedCanvas || !renderedCanvas.value) {
    return;
  }

  const ctx = renderedCanvas.value.getContext('2d', { willReadFrequently: true });
  const header = document.getElementById('main-nav');
  const headerBottom = header.clientHeight;
  const logoIcon = document.getElementById('logo-branded-g');
  const logoOffset = logoIcon.getBoundingClientRect().top;
  const logoHeight = logoIcon.clientHeight;
  const logoBottom = logoOffset + logoHeight;
  const scrollOffset = window.scrollY;

  // Calculate where to start capturing from #main
  const captureOffsetY = Math.max(0, scrollOffset - headerBottom);

  // Calculate blank area to simulate the space between header and content
  const scrolledContentUnderLogoHeight = Math.max(0, scrollOffset - (headerBottom - logoBottom));
  const blankSpaceHeight = Math.max(0, logoHeight - scrolledContentUnderLogoHeight);

  // Clear canvas before each draw
  ctx.clearRect(0, 0, renderedCanvas.value.width, renderedCanvas.value.height);

  // Draw the portion of #main that should appear as scrolling under the header
  ctx.drawImage(
    capturedCanvas,
    0, // Start x position of the portion to capture
    captureOffsetY, // Start y position of the portion to capture
    250, // Width of the portion to capture (stretch)
    logoIcon.clientHeight - blankSpaceHeight, // Height of the portion to capture
    0, // x position in canvas to draw
    blankSpaceHeight, // y position in canvas to draw, after blank space
    renderedCanvas.value.width, // width (stretch)
    renderedCanvas.value.height - blankSpaceHeight, // height
  );
}

function handleScroll(): void {
  drawCapturedCanvas();
}
</script>

<style lang="scss" module>
.container {
  align-items: flex-start;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: relative;
  top: 0;
  right: 0;

  .wrapper {
    position: absolute;
    height: px-to-rem(33);
    width: px-to-rem(33);

    .mask {
      height: 100%;
      position: absolute;
      width: 100%;

      image {
        height: px-to-rem(33);
        width: px-to-rem(33);
      }
    }

    .render {
      height: 100%;
      mask: url(#logo-mask);
      overflow: hidden;
      position: absolute;
      width: 100%;
      z-index: 1000;
      -webkit-mask: url(#logo-mask);

      canvas {
        filter: blur(10px) saturate(2.6);
        height: px-to-rem(33);
        transform: scale(3);
        width: px-to-rem(33);
        -webkit-filter: blur(10px) saturate(2.6);
      }
    }

    .background {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999;

      img {
        display: block;
        position: relative;
      }
    }
  }
}

@media (min-width: $break-point) {
  .container {
    .wrapper {
      width: px-to-rem(50);
      height: px-to-rem(50);

      .mask {
        image {
          width: px-to-rem(50);
          height: px-to-rem(50);
        }
      }

      .render {
        canvas {
          width: px-to-rem(50);
          height: px-to-rem(50);
        }
      }
    }
  }
}
</style>

<template>
  <footer id="gugg-footer" aria-label="Footer" :class="$style.footer" role="contentinfo">
    <div v-for="(row, idx) in footerNav.rows" :key="idx" :class="$style.row">
      <div :class="get_row_class(row)">
        <div
          v-for="(block, indx) in row.blocks"
          :key="indx"
          :class="[get_block_class(block), $style.block]"
        >
          <h3 v-if="block.heading">{{ block.heading }}</h3>
          <span v-if="block.type === 'text' && block.text" v-html="block.text"></span>
          <PreparedAnchor
            v-if="block.type === 'text' && block.more.more_link && block.more.more_link_text"
            class="more-link"
            :ga4-data='`{"click_type": "navigation","component": "FooterNav","gtm_tag": "navigation","link_text": "${block.more.more_link_text}","link_url": "${block.more.more_link}"}`'
            :to="block.more.more_link"
            :behavior="block.more.more_link_behavior"
          >
            {{ block.more.more_link_text }}
          </PreparedAnchor>
          <ul
            v-if="
              (block.type === 'link_list' || block.type === 'legalese' || block.type === 'social') &&
              block.links && block.links.length
            "
          >
            <li v-for="(link, index) in block.links" :key="index">
              <PreparedAnchor
                :ga4-data='`{"click_type": "navigation","component": "FooterNav","gtm_tag": "navigation","link_text": "${link.link_text}","link_url": "${link.link}"}`'
                :to="link.link"
                :behavior="link.link_behavior"
              >
                {{ link.link_text }}
              </PreparedAnchor>
            </li>
          </ul>
          <p v-if="block.type === 'legalese'">
            &copy; {{ appConfig.today.year }} The Solomon R. Guggenheim Foundation
          </p>
          <div
            v-if="block.type === 'constellation' || block.type === 'logo'"
            :class="{ [$style.logoGrid]: block.heading?.includes('Partners') }"
          >
            <picture
              v-for="(logo, key) in block.logos"
              :class="{
                [get_grid_class(block.logos.length)]: !block.heading?.includes('Partners'),
              }"
              :key="key"
            >
              <PreparedAnchor v-if="logo.link" :to="logo.link" :behavior="logo.link_behavior">
                <img
                  :alt="logo.alt"
                  :src="logo.src"
                  :class="[{ [$style.unesco]: block.heading?.includes('Unesco') }]"
                  loading="lazy"
                />
              </PreparedAnchor>
              <img
                v-if="!logo.link"
                :alt="logo.alt"
                :src="logo.src"
                :class="[{ [$style.unesco]: block.heading?.includes('Unesco') }]"
                loading="lazy"
              />
            </picture>
          </div>
          <div v-if="block.type === 'partners'">
            <img
              :alt="isMobile() ? block.partners.mobile_logo.alt : block.partners.desktop_logo.alt"
              :src="isMobile() ? block.partners.mobile_logo.src : block.partners.desktop_logo.src"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { AppConfig } from '@/types/global.interface';
import { inject, useCssModule } from 'vue';
import PreparedAnchor from '@/components/common/PreparedAnchor.vue';
import { useScreenSize } from '@/composables/Common.js';

interface Block {
  heading?: string;
  links?: Array<{
    link: string;
    link_behavior: string;
    link_text: string;
  }>;
  logo?: {
    alt: string;
    src: string;
  };
  more?: {
    more_link: string;
    more_link_behavior: string;
    more_link_text: string;
  };
  text?: string;
  type: string;
}

interface Blocks {
  blocks: Array<Block>;
}

interface FooterNav {
  rows: Array<Blocks>;
}

const appConfig = inject<AppConfig>('appConfig');
const footerNav = inject<FooterNav>('footerNav');

const { isMobile } = useScreenSize();
const style = useCssModule();

function get_block_class(block: Block): string {
  return style[block.type];
}

function get_grid_class(count): string {
  if (isMobile()) {
    return 'span-twelve';
  }
  const cols = 12 / count;
  switch (cols) {
    case 12:
      return 'span-twelve';
    case 6:
      return 'span-six';
    case 4:
      return 'span-four';
    case 3:
      return 'span-three';
  }
}

function get_row_class(row: Blocks): string {
  if ('constellation' === row['blocks'][0]['type'] || 'logo' === row['blocks'][0]['type']) {
    return `container ${style.logos}`;
  }

  return 'container grid';
}
</script>

<style lang="scss" module>
.block {
  border-bottom: 1px solid $pale-gray;
  grid-column: span 12;
  padding: 1rem;
}

.constellation {
  a {
    display: block;
  }

  div {
    display: grid;
    grid-template-columns: auto;
    row-gap: 1.25rem;
  }

  img {
    height: px-to-rem(34.4);
  }
}

.footer {
  @include body-2;

  & {
    background: $black;
    color: $white-smoke;
    margin-top: 4rem;
    padding: 2.5rem 0 6rem;
  }

  a {
    color: $white-smoke;
    text-decoration: none;

    &:active {
      color: $light-gray;
    }

    &:focus {
      background-color: $white-smoke;
      color: $black;

      img {
        filter: invert(1);
        outline: 5px solid #000; // #000 as it's opposite of #fff
      }

      &:active {
        background-color: $white;
        color: $light-gray;
      }

      &:hover {
        background-color: $silver;
        color: $medium-gray;
      }
    }

    &:hover {
      color: $silver;
      text-decoration: underline;
    }
  }

  :global(.button-secondary) {
    background-color: $black;
    border-color: $white-smoke;
    color: $white-smoke;

    svg {
      fill: $white-smoke;
    }

    &:hover {
      background-color: $white-hover;
      border-color: $white-hover;
      color: $black;
      text-decoration: underline;

      svg {
        fill: $black;
      }
    }

    &:active {
      background-color: $white-pressed;
      border-color: $white-pressed;
      color: $black;

      svg {
        fill: $black;
      }
    }

    &:focus {
      background-color: $black;
      color: $white;
      outline-color: $white;
    }
  }

  h3 {
    @include body-2;

    & {
      color: $white-smoke;
      font-weight: $bold;
      margin-top: 0;
    }
  }

  li {
    list-style: none;
    margin-top: 0.75rem;
  }

  :global(.more-link) {
    @include body-2;

    & {
      color: $white-smoke;
      font-weight: $bold;
      padding-bottom: 0;
      padding-top: 0;
      text-transform: none;
    }

    &:focus {
      color: $black;

      &:after {
        color: $black;
      }
    }

    &:hover {
      svg {
        fill: $black;
      }
    }

    &:active {
      svg {
        fill: $black;
      }
    }
  }

  p {
    @include body-2;
  }

  .partners {
    img {
      margin: 2rem 0;
      max-height: px-to-rem(325);
    }
  }

  ul {
    @include body-2;

    & {
      margin-left: 0;
      padding-left: 0;
    }
  }
}

.logo {
  > div {
    margin-top: 2rem;
  }

  img {
    height: px-to-rem(82);
  }

  svg {
    margin-top: 2rem;
    height: px-to-rem(82);
  }
}

.logoGrid {
  column-gap: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  margin-bottom: 3rem;
  row-gap: 2rem;

  img {
    height: 2.5rem;

    &.unesco {
      height: px-to-rem(82);
      max-width: initial;
    }
  }
}

.logos {
  > div {
    margin-top: 0;
  }
}

.row {
  &:last-of-type {
    .block {
      &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
}

.text {
  p {
    margin-top: 0.75rem;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    :global(.button-secondary) {
      margin-top: 1.5rem;
    }
  }
}

@media (min-width: $break-point) {
  .block {
    border-bottom: none;
    margin: 0;
    padding: 0;
  }

  .constellation {
    div {
      display: flex;
      justify-content: space-between;
    }
  }

  .footer {
    margin-top: 6rem;
    padding: 1.625rem 0 6rem;

    li,
    :global(.more-link) {
      margin-top: 0.5rem;
    }

    .partners {
      img {
        margin: px-to-rem(12) 0;
        max-height: px-to-rem(87);
      }
    }
  }

  .legalese {
    display: flex;

    ul {
      display: flex;
      gap: 2rem;
      margin-top: 0;

      li {
        margin-top: 0;
      }
    }

    p {
      margin-left: auto;
      margin-top: 0;
    }
  }

  .link_list {
    grid-column: span 3;
    margin-top: 0;
  }

  .logoGrid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .more {
    white-space: pre-wrap;
  }

  .row {
    border-bottom: 1px solid $pale-gray;
    margin-bottom: px-to-rem(28);
    padding-bottom: px-to-rem(28);

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    :global(.container) {
      padding: 0 2rem;
    }
  }

  .social {
    grid-column: span 4;
    margin-top: 0;

    ul {
      display: block;
    }
  }

  .text {
    grid-column: span 4;
    margin-top: 0;

    p {
      margin-top: 0.5rem;
    }
  }
}
</style>

import { decodeContent, stripTags } from '@/utils';
import { computed, nextTick, onBeforeUnmount, onMounted, onUnmounted, ref, unref } from 'vue';
import { storeToRefs } from 'pinia';
import { useMainStore } from '@/stores/mainStore.js';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';

export const globalAudioMuted = ref(true);

// A11y
export function useA11y() {
  const store = useMainStore();
  const { setValue } = store;

  let firstFocusable = false;
  let lastFocusable = false;

  const detectKeyboardUser = () => {
    const els = [...document.getElementsByTagName('a'), ...document.getElementsByTagName('button')];

    ['click', 'keydown'].map((type) => {
      if (els) {
        Array.from(els).forEach((el) => {
          el.addEventListener(type, (event) => {
            // Event.detail is the click count, keydown enter also triggers click
            if (event.type === 'keydown' && event.code === 'Enter' && event.detail === 0) {
              setValue({ type: 'isKeyboardUser', value: true });
            }

            if (event.type === 'click' && event.detail > 0) {
              setValue({ type: 'isKeyboardUser', value: false });
            }
          });
        });
      }
    });
  };

  const findFocusable = (el) => {
    return el.querySelectorAll(
      'a[href]:not(a[id^="anchor-link"]):not([aria-hidden="true"]), area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]',
    );
  };

  const focusFirstChild = (el) => {
    // TODO: Add check for el in $refs and fallback to ID?
    nextTick().then(() => {
      const target = document.getElementById(unref(el));

      if (target) {
        const focusable = findFocusable(target);

        if (focusable.length) {
          focusable[0].focus();
        }
      }
    });
  };

  const focusSkip = () => {
    document.getElementById('skip-link').focus();
  };

  const keyboardHandler = (e) => {
    if (e.key === 'Tab' || e.code === 'Tab') {
      if (e.shiftKey && document.activeElement === firstFocusable) {
        e.preventDefault();
        lastFocusable.focus();
      }

      if (!e.shiftKey && document.activeElement === lastFocusable) {
        e.preventDefault();
        firstFocusable.focus();
      }
    }
  };

  const trapFocus = (target = null) => {
    let focusableElements;
    let trappedElement;

    if (target) {
      focusableElements = findFocusable(target);

      if (focusableElements.length > 0) {
        firstFocusable = focusableElements[0];
        lastFocusable = focusableElements[focusableElements.length - 1];
        firstFocusable.focus();
        trappedElement = target;
        trappedElement.addEventListener('keydown', keyboardHandler);
      }
    }

    if (!target) {
      if (trappedElement) {
        trappedElement.removeEventListener('keydown', keyboardHandler);
      }
    }
  };

  return {
    detectKeyboardUser,
    findFocusable,
    focusFirstChild,
    focusSkip,
    keyboardHandler,
    trapFocus,
  };
}

// Analytics
export function useAnalytics() {
  const ga4Event = (params) => {
    window.dataLayer.push({
      event: 'ga4-event',
      name: 'click',
      params,
    });
  };

  const initAnalytics = () => {
    const interval = window.setInterval(() => {
      const anchors = window.document.body.querySelectorAll('a[ga4-event]:not([ga-init])');
      const buttons = window.document.body.querySelectorAll('button[ga4-event]:not([ga-init])');
      const untagged = [...anchors, ...buttons];

      if (untagged.length > 0) {
        untagged.forEach((elem) => {
          elem.setAttribute('ga-init', '');

          if (elem.hasAttribute('ga4-event')) {
            const gaData = elem.getAttribute('ga4-event');

            if (gaData) {
              try {
                const eventData = JSON.parse(gaData);

                elem.addEventListener('click', () => {
                  ga4Event(eventData);
                });
              } catch {
                // eslint-disable-next-line
                console.warn('Cannot parse: ' + gaData);
              }
            }
          }
        });
      }

      if (untagged.length === 0) {
        clearInterval(interval);
      }
    }, 100);
  };

  return { ga4Event, initAnalytics };
}

// CheckAncestry
export function useCheckAncestry() {
  const hasSpanNine = ref(false);

  const findAncestor = (node) => {
    if (node && node.vnode.el.id === 'app') {
      return false;
    }

    if (node && node.refs.mainContent) {
      return node.refs.mainContent;
    }

    return node ? findAncestor(node.parent) : null;
  };

  const hasSpanNineAncestor = (instance) => {
    const ancestor = findAncestor(instance.parent);

    if (ancestor) {
      if (ancestor.vnode && ancestor.vnode.__v_isVNode) {
        hasSpanNine.value = ancestor.vnode.el.classList.contains('span-nine');
      }

      if (!ancestor.vnode) {
        if (ancestor.classList) {
          hasSpanNine.value = ancestor.classList.contains('span-nine');
        }

        if (!ancestor.classList) {
          hasSpanNine.value = ancestor._.attrs.class.includes('span-nine');
        }
      }
    }
  };

  return { findAncestor, hasSpanNine, hasSpanNineAncestor };
}

export function useSpacingState(props, $style) {
  return computed(() => {
    switch (props.spacingState) {
      case 'Inset':
        return $style.inset;
      case 'Column':
        return $style.column;
      case 'Full Screen':
      case 'None':
      default:
        return $style.fullScreen;
    }
  });
}

// DataLoaded
export function dataLoaded(loaded = true) {
  const store = useMainStore();
  const { setValue } = store;

  if (loaded) {
    window.dataLayer.push({
      event: 'data-loaded',
    });
  }

  setValue({ type: 'dataLoaded', value: loaded });
}

// DateFormat
/**
 * Parse time and returns it as HH:MM
 * @param {string} time
 * @returns {string}
 */
const checkTime = (time) => {
  let hours;
  let minutes = '00';

  if (time.indexOf(':') > -1) {
    hours = time.split(':')[0];
    minutes = time.split(':')[1];

    if (hours.length === 1) {
      hours = '0' + hours;
    }
  }

  if (time.indexOf(':') === -1) {
    if (time.length === 1) {
      hours = '0' + time;
    }

    if (time.length === 2) {
      hours = time;
    }
  }

  return `${hours}:${minutes}`;
};

/**
 * Determines if "start" value ends tomorrow
 * @param {object} start
 * @param {object} end
 * @returns {boolean}
 */
const endsTomorrow = (start, end) => {
  const tomorrow = new Date(`${start.isoDate}T00:00:00`);

  tomorrow.setDate(tomorrow.getDate() + 1);

  const d1 = format(tomorrow);
  const d2 = end.isoDate || false;

  return d1 === d2;
};

/**
 * Returns formatted string from Date object
 * @param {Date} date
 * @param {boolean} showMinutes
 * @returns {string}
 */
const format = (date, showMinutes = false) => {
  // en-GB = "20/12/2023"
  const formatted = date.toLocaleDateString('en-GB').split('/').reverse().join('-');

  if (showMinutes) {
    // it-IT = "02:03:04"
    return formatted + ' ' + date.toLocaleTimeString('it-IT');
  }

  return formatted;
};

export function useDateFormat() {
  /**
   * CalendarDay
   * CalendarMonth
   * DateLine
   * SiteSearchList
   * EventSummary
   * @param {*} date
   * @returns {string}
   */
  const dateTime = (date) => {
    if (!(date instanceof Date)) {
      if (date.isoDate) {
        const temp = date.time
          ? `${date.isoDate}T${checkTime(date.time)}:00`
          : `${date.isoDate}T00:00:00`;

        return temp;
      }

      if (!date.isoDate) {
        const month = new Date(`${date.month} 1, 2023`).getMonth() + 1;
        const temp = date.time
          ? `${date.year}-${month}-${date.day}T${checkTime(date.time)}:00`
          : `${date.year}-${month}-${date.day}T00:00:00`;

        return temp;
      }
    }

    return date.toISOString();
  };

  /**
   * CalendarDay
   * CalendarEvent
   * CalendarSection
   * ExhibitionSlider
   * GuggenFlyout
   * ArticleSummary
   * @param {*} date
   * @param {boolean} weekday
   * @returns {Date || string}
   */
  const formatDate = (date, weekday = false) => {
    if (!(date instanceof Date)) {
      return weekday
        ? `${date.weekday}, ${date.month} ${date.day}, ${date.year}`
        : `${date.month} ${date.day}, ${date.year}`;
    }

    return date;
  };

  /**
   * CalendarEvent
   * DateLine
   * SiteSearchList
   * EventSummary
   * @param {*} dates
   * @returns {string}
   */
  const formatDates = (dates) => {
    if (!Array.isArray(dates)) {
      const temp = dates;
      dates = [];
      dates.push(temp);
    }

    const begin = dates[0].start;
    const end = dates[dates.length - 1].start;

    // If no valid end date, return only formatted start
    if (end === begin) {
      return formatDate(begin);
    }

    if (`${begin.month} ${begin.day} ${begin.year}` === `${end.month} ${end.day} ${end.year}`) {
      return formatDate(begin);
    }

    if (begin.year === end.year) {
      if (begin.month === end.month) {
        return `${begin.month} ${begin.day}&ndash;${end.day}, ${end.year}`;
      }

      return `${begin.month} ${begin.day}&ndash;${end.month} ${end.day}, ${end.year}`;
    }

    return `${begin.month} ${begin.day}, ${begin.year}&ndash;${end.month} ${end.day}, ${end.year}`;
  };

  /**
   * CalendarDay
   * CalendarMonth
   * @param {object} date
   * @param {boolean} showMeridiem
   * @returns {string}
   */
  const formatTime = (date, showMeridiem = true) =>
    showMeridiem ? `${date.time} ${date.meridiem}` : date.time;

  /**
   * CalendarEvent
   * EventSummary
   * @param {*} dates
   * @returns {string}
   */
  const formatTimeSpan = (dates) => {
    if (!Array.isArray(dates)) {
      const temp = dates;
      dates = [];
      dates.push(temp);
    }

    const begin = dates[0].start;
    const end =
      dates[0].start === dates[dates.length - 1].end ||
      dates[dates.length - 1].end === null ||
      dates[dates.length - 1].end === ''
        ? null
        : dates[dates.length - 1].end;

    const startMeridiem = begin.meridiem;
    const showMeridies = end && startMeridiem !== end.meridiem;

    // Ends tomorrow at 12 am (midnight)
    if (end && endsTomorrow(begin, end) && `${end.time} ${end.meridiem}` === '12 am') {
      return `${formatTime(begin)} ${begin.timezone} to Midnight`;
    }

    return end
      ? `${formatTime(begin, showMeridies)}&ndash;${formatTime(end)} ${begin.timezone}`
      : `${formatTime(begin)} ${begin.timezone}`;
  };

  /**
   * Format JS Date to "Tuesday(optional), February 4, 2023"
   * @param {Date} date
   * @param {boolean} includeWeekday
   * @returns {string}
   */
  const formatToString = (date, includeWeekday = false) => {
    let options = {
      day: 'numeric',
      year: 'numeric',
      month: 'long',
    };

    if (includeWeekday) {
      options = { weekday: 'long', ...options };
    }

    return date.toLocaleString('en-US', options);
  };

  return {
    dateTime,
    formatDate,
    formatDates,
    formatTime,
    formatTimeSpan,
    formatToString,
  };
}

// ErrorHandler
export function useHandleError() {
  const router = useRouter();

  const handleError = (component, msg, shouldRoute = true, path = false) => {
    const params = {
      click_type: 'component_error',
      component,
      content_group: 'error',
      link_text: msg,
      gtm_tag: 'interaction',
    };

    window.dataLayer.push({
      event: 'ga4-event',
      name: 'error',
      params,
    });

    if (shouldRoute && path) {
      router.push({ path: `${path}` });
    }

    if (shouldRoute && !path) {
      router.push({ name: 'NotFound' });
    }
  };

  return { handleError };
}

// FilterInteractions
function handleEscKey(event, selected, handleClickOutside) {
  if (event.key === 'Escape' && selected.value) {
    selected.value = '';
    document.removeEventListener('click', handleClickOutside);
  }
}

export function useFilterInteractions(selected) {
  const store = useMainStore();
  const { isKeyboardUser } = storeToRefs(store);

  const { focusFirstChild } = useA11y();

  const clickListener = new AbortController();
  const keyListener = new AbortController();

  function handleClickOutside() {
    selected.value = '';
    document.removeEventListener('click', handleClickOutside);
  }

  onBeforeUnmount(() => {
    clickListener.abort();
    keyListener.abort();
  });
  onMounted(() =>
    nextTick(() =>
      document.addEventListener(
        'keydown',
        (event) => handleEscKey(event, selected, handleClickOutside),
        { signal: keyListener.signal },
      ),
    ),
  );

  const selectNav = (selection, refs) => {
    selected.value = selected.value === selection ? '' : selection;
    document.addEventListener('click', handleClickOutside);
    refs.forEach((ref) => {
      if (ref) {
        ref.addEventListener('click', (event) => event.stopPropagation(), {
          signal: clickListener.signal,
        });
      }
    });

    if (isKeyboardUser.value && selected.value) {
      focusFirstChild(selection);
    }

    if (!selected.value) {
      document.removeEventListener('click', handleClickOutside);
    }
  };

  return { selectNav };
}

// ImageProxy
export function useImageProxy() {
  const proxySource = (path, width, full = null) => {
    if (path) {
      if (full || !path.includes('weserv')) {
        return path;
      }

      if (path.includes('weserv')) {
        return width
          ? `${path}&w=${width}`
          : `${path}&w=${window.innerWidth < 960 ? window.innerWidth : 1280}`;
      }
    }

    return '';
  };

  return { proxySource };
}

// Metadata
export function useMetaData() {
  const store = useMainStore();
  const { setValue } = store;

  const defaultMetaDescription =
    'Visit the Frank Lloyd Wright–designed Guggenheim Museum in NYC, part of a UNESCO World Heritage Site. See the renowned permanent collection and special exhibitions.';
  const defaultMetaTitle = 'The Guggenheim Museums and Foundation';

  const purgeMetaTags = () => {
    Array.from(document.querySelectorAll('[data-app-meta]')).map((el) =>
      el.parentNode.removeChild(el),
    );
  };

  const setMetaDescription = (description = '') => {
    purgeMetaTags();

    const metaDescription = description ? stripTags(description) : defaultMetaDescription;
    const metaTags = [
      {
        name: 'description',
        content: metaDescription,
      },
    ];

    setMetaTags(metaTags);
  };

  const setMetaTags = (metaTags) => {
    metaTags
      .map((tagDef) => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-app-meta', '');

        return tag;
      })
      .forEach((tag) => document.head.appendChild(tag));
  };

  const setMetaTitle = (title = '') => {
    setValue({
      type: 'announcement',
      value: `The ${title} page has loaded. Skip to main content.`,
    });
    document.title = title ? `${decodeContent(title)} | ${defaultMetaTitle}` : defaultMetaTitle;
  };

  return {
    setMetaDescription,
    setMetaTitle,
  };
}

// Navigation
export function useNavigation() {
  const { findFocusable } = useA11y();
  const router = useRouter();

  const checkIsSamePath = (url, emit, event) => {
    if (
      url === router.currentRoute.value.fullPath ||
      router.currentRoute.value.hash ||
      router.currentRoute.value.query
    ) {
      emit('isSamePath');
    }

    if (event && event.key === 'Enter') {
      // handles keyboard activation
      if (url.includes('#')) {
        router.push({ path: url.split('#')[0], hash: `#${url.split('#')[1]}` });

        nextTick().then(() => {
          const target = document.querySelector(`#${url.split('#')[1]}`);
          const firstFocusable = findFocusable(target)[0];

          firstFocusable.focus();
        });
      }

      if (url.includes('?q=')) {
        router.push({ path: url.split('#')[0], query: { q: `${url.split('?q=')[1]}` } });
      }
    }
  };

  return { checkIsSamePath };
}

// RouteParent
export function useRouteParent() {
  const route = useRoute();

  const parent = route.matched[route.matched.length - 1].name.toString();

  return { parent };
}

// ScreenSize
export function useScreenSize() {
  const innerHeight = ref(window.innerHeight);
  const innerWidth = ref(window.innerWidth);
  const mobileBreakpoint = 960;
  const tabletBreakpoint = 915;

  onMounted(() => window.addEventListener('resize', handleResize));
  onUnmounted(() => window.removeEventListener('resize', handleResize));

  const handleResize = () => {
    innerHeight.value = window.innerHeight;
    innerWidth.value = window.innerWidth;
  };

  const isDesktop = () => innerWidth.value >= tabletBreakpoint;

  const isMobile = () => innerWidth.value < mobileBreakpoint;

  const isTablet = () => mobileBreakpoint < innerWidth.value && innerWidth.value < tabletBreakpoint;

  return { isDesktop, isMobile, isTablet };
}

// ScrollAnchor
export function useScrollAnchor() {
  const { findFocusable } = useA11y();

  const scrollToAnchor = (target) => {
    if (target) {
      const marginTop = parseFloat(getComputedStyle(target).marginTop);

      window.scrollTo(0, target.offsetTop - marginTop - 100);
      let isFocusable = false;
      let shouldSkip = false;

      findFocusable(document.querySelector('#content')).forEach((item) => {
        if (shouldSkip) {
          return;
        }

        if (item.isSameNode(target)) {
          isFocusable = true;
          shouldSkip = true;
        }
      });

      if (!isFocusable) {
        target.setAttribute('tabindex', 0);
      }

      target.focus();
      target.removeAttribute('tabindex');
    }
  };

  return { scrollToAnchor };
}

// UniqueIdentifier
let uid = 0;

export function useUniqueIdentifier() {
  const incrementUid = () => {
    uid += 1;
  };

  incrementUid();

  return { uid: uid };
}

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#111",
      d: "M8.667 9.333H7.334V6h1.333v3.333Zm0 2.667H7.334v-1.334h1.333V12Zm-8 2h14.667L8 1.333.667 14Z"
    }, null, -1)
  ])))
}
export default { render: render }